@use 'sass:map';
@import '~@proton/styles/scss/lib';

.eventpopover {
	position: absolute;
	z-index: map.get($z-indexes, 'floating-frames');
	transform-origin: 50%;
	padding: rem(24);
	overflow: auto;
	background: var(--background-norm);
	color: var(--text-norm);
	animation: eventpopoverIn ease 0.15s;
	animation-fill-mode: both;
	border: 1px solid var(--border-weak);

	@include media('<=small') {
		inset: 0;
		inline-size: 100%;
		block-size: 100%;
	}

	@include media('>small') {
		inline-size: rem(500);
		max-inline-size: rem(368);
		max-block-size: 100%;
		border-radius: var(--border-radius-lg);
		box-shadow: var(--shadow-lifted);
	}

	@include media('<=small') {
		padding-inline: rem(16);
	}

	@include media('print') {
		inline-size: rem(368);
		block-size: fit-content;
		border: 1px solid var(--border-norm);
	}

	&,
	&:focus-visible,
	&:focus:focus-visible {
		outline: none;
	}

	// transition: all .25s ease; should be applied only when opened and end of animation <-- this is solved by react-transition-group
	&-header {
		&--draggable {
			padding-block: rem(5);
			padding-inline: rem(8);
			margin-block: rem(-24) rem(24);
			margin-inline: rem(-24);
			cursor: grab;
			background-color: var(--background-weak);
			border-block-end: 1px solid var(--border-weak);

			&:active {
				cursor: grabbing;
			}
		}
	}

	&-actions {
		> * + * {
			margin-inline-start: rem(8);
		}

		&-separator {
			background-color: var(--border-norm);
			inline-size: rem(1);
			block-size: rem(28);
		}
	}

	&-title {
		font-size: rem(24);
	}

	.participant {
		min-block-size: rem(32);

		&-text {
			line-height: rem(20);
		}

		&-extra-text {
			line-height: rem(16);
		}

		&:first-child,
		&.is-organizer,
		+ .participant {
			margin-block-start: em(12); // from design
		}

		&.needsAction {
			border-color: var(--border-norm);
		}

		&-display {
			border-radius: var(--border-radius-md);
		}
	}

	.partstatIcon {
		position: absolute;
		inset-inline-end: 0;
		inset-block-end: 0;
		transform: translate3d(50%, 50%, 0);
	}

	.tabs {
		min-block-size: 12em;
	}

	// special fix when no select for calendar ------------------
	&-calendar-select:has(.field--frozen) {
		.label {
			padding-block: 0;
		}

		.field--frozen {
			// let it go
			block-size: auto;
			padding-block: 0;
		}
	}

	// special fix for popover line with fullday checkbox + recurrency (DE) ------------------
	&-fullday-recurrency {
		body.is-drawer-app & {
			align-items: start;
			gap: em(8, 16);
		}

		@include media('>=#{em(350, 16)}') {
			flex-flow: row nowrap;

			.eventpopover-recurrency {
				margin-block-start: 0;
				margin-inline-start: auto;
			}
		}
		@include media('<#{em(350, 16)}') {
			button {
				flex-direction: row-reverse;
				justify-content: flex-end;
			}
		}
	}

	// special fixes for recurring frequency ------------------
	.noInEventPopover {
		display: none;
	}

	.onpopover-flex-column {
		flex-direction: column;

		& > * {
			inline-size: 100%;
			flex-basis: auto;
		}
	}

	// --------------------------------------------------------
}

@keyframes eventpopoverIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
