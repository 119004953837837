@import '~@proton/styles/scss/lib';

.main-dropzone {
	&-image {
		margin-block-end: rem(40);
	}

	&-heading {
		margin-block-end: rem(8);
	}
}
