@use "sass:map";

.calendar-loader-container {
	@extend .notifications-container;

	position: absolute;
	z-index: map.get($z-indexes, 'up');
	inset-block-start: em(160);
	inset-block-end: unset;
	inset-inline-start: 50%;

	@include media('>small') {
		inset-block-start: em(140);
	}

	.notification {
		--top-custom: 0;
	}
}
