.calendar-daygrid {
	min-block-size: rem(410);

	@include media('print') {
		min-block-size: rem(700);
	}

	&-weeknumber {
		border-block-end: $calendar-grid-border;
		border-inline-end: $calendar-grid-border;
		background-color: var(--background-weak);

		&-width {
			inline-size: rem(25);
		}
	}

	&-row,
	&-days {
		border-block-end: $calendar-grid-border;
	}

	&-day {
		@include media('screen') {
			&[aria-current='true'] {
				font-weight: var(--font-weight-bold);
				color: var(--primary);
			}
		}
	}

	&-columns {
		position: absolute;
		inset: 0;
	}

	&-column:not(:last-child) {
		border-inline-end: $calendar-grid-border;
	}
}
