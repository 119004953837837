.calendar-monthgrid {
	&-day {
		color: var(--text-norm);

		&-number {
			display: inline-flex;
			inline-size: 1.75em;
			block-size: 1.75em;
			border-radius: var(--border-radius-md);
			border: 1px solid transparent;
			transition: 0.15s easing(ease-out-quint);
		}

		&:hover &-number,
		&:focus &-number {
			background-color: var(--interaction-default-hover);
		}

		&:active &-number {
			background-color: var(--interaction-default-active);
		}

		@include media('screen') {
			&[aria-current='date'] {
				.calendar-monthgrid-day-number {
					color: var(--primary);
					border-color: var(--primary);
					font-weight: var(--font-weight-bold);
				}

				&:hover,
				&:focus {
					.calendar-monthgrid-day-number {
						color: var(--interaction-norm-hover);
						border-color: var(--interaction-norm-hover);
						background-color: var(--interaction-default-hover);
					}
				}

				&:active {
					.calendar-monthgrid-day-number {
						color: var(--interaction-norm-active);
						border-color: var(--interaction-norm-active);
						background-color: var(--interaction-default-active);
					}
				}
			}

			@include media('<=small') {
				&[aria-pressed='true'] &-number {
					background-color: var(--primary);
					color: var(--primary-contrast);
					font-weight: var(--font-weight-bold);
				}
			}
		}
	}
}
