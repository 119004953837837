@import '~@proton/styles/scss/lib';

$calendar-aside-width: rem(60) !default;

// trick is we need to calculate roughly the available height = whole height - header - week bar / 24
// then maximum between the minimal height and the one adapted for larger screens.
$calendar-hour-height-minimum: rem(48) !default;
$calendar-hour-height-max: calc((100lvh - #{rem($header-height)} - #{rem(76)}) / 24);
$calendar-hour-height: max(#{$calendar-hour-height-minimum}, #{$calendar-hour-height-max}) !default;
$calendar-grid-border: 1px solid var(--border-weak);
$calendar-gridcell-margin: rem(10) !default;
$calendar-gridcell-gutter: rem(3) !default;
