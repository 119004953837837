@import '~@proton/styles/scss/lib';

.calendar-fullday-row {
	border-block-end: $calendar-grid-border;
	padding-block-end: $calendar-gridcell-gutter;

	.calendar-aside {
		margin-block-end: -$calendar-gridcell-gutter;
	}
}

.calendar-row-heading {
	z-index: 1;

	> div {
		padding-inline-end: rem(11);
	}
}

.calendar-first-row-heading {
	@include media('<=medium') {
		font-size: rem(12);
	}
}

@include media('<=small') {
	// we show abbreviated version only on week/month view
	.is-week-view,
	.is-month-view {
		.calendar-grid-heading-day-fullname {
			border: 0;
			clip: rect(0 0 0 0);
			block-size: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			inline-size: 1px;
		}
	}

	// thus hide the abbrievated version on day view
	.is-day-view {
		.calendar-grid-heading-day-shortname {
			display: none;
		}
	}
}
