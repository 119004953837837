// fix for Safari mobile shitty way to handle vh unit
.is-day-view,
.is-week-view,
.is-month-view {
	.calendar-time-grid::after {
		content: '';
		display: flex;
		inline-size: 100%;
		block-size: 5em;
	}
}

@include media('<=small') {
	.is-day-view,
	.is-week-view,
	.is-month-view {
		.calendar-time-grid::after {
			padding-block-end: env(safe-area-inset-bottom); // thanks Apple for the fixed toolbar at the bottom
		}
	}
}

// hide the thing on desktop devices supporting media query 4 spec
@media (pointer: fine) {
	.is-day-view,
	.is-week-view,
	.is-month-view {
		.calendar-time-grid::after {
			content: none;
		}
	}
}
