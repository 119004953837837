.calendar-grid {
	&-heading {
		color: var(--text-norm);
		font-weight: var(--font-weight-semibold);

		@include media('>small') {
			flex-wrap: nowrap;
		}

		@include media('>small', '<large') {
			padding-inline: 0;
		}

		&-day {
			@include media('<=small') {
				inline-size: 100%;
			}

			@include media('>small') {
				margin-inline-end: rem(2);
			}
		}

		&-number {
			border-radius: var(--border-radius-sm);
			border: 1px solid transparent;
			line-height: 1;
			inline-size: 1.75em;
			block-size: 1.75em;
			transition: 0.15s easing(ease-out-quint);

			@include media('>small') {
				block-size: rem(28);
				inline-size: rem(28);
			}
		}

		&:hover &-number,
		&:focus &-number {
			background-color: var(--interaction-default-hover);
		}

		&:active &-number {
			background-color: var(--interaction-default-active);
		}

		@include media('screen') {
			&[aria-current='date'] {
				.calendar-grid-heading-day {
					@include media('>small') {
						margin-inline-end: rem(6);
					}
				}

				.calendar-grid-heading-number {
					border-color: var(--primary);
					color: var(--primary);
					background-color: var(--interaction-default);
				}

				&:hover,
				&:focus {
					.calendar-grid-heading-number {
						color: var(--interaction-norm-hover);
						border-color: var(--interaction-norm-hover);
						background-color: var(--interaction-default-hover);
					}
				}

				&:active {
					.calendar-grid-heading-number {
						color: var(--interaction-norm-active);
						border-color: var(--interaction-norm-active);
						background-color: var(--interaction-default-active);
					}
				}
			}
		}

		@include media('<=small') {
			&[aria-pressed='true'] &-number {
				@include media('screen') {
					background-color: var(--primary);
					color: var(--primary-contrast);
				}
			}
		}
	}

	&-gridcells {
		border-block-end: $calendar-grid-border;
	}

	&-dayLine,
	&-hourLine,
	&-gridcell {
		&::after {
			content: '';
			position: absolute;
			pointer-events: none;
		}
	}

	&-dayLine {
		&::after {
			block-size: 100%;
			margin-inline-start: -1px;
			border-inline-start: $calendar-grid-border;
		}
	}

	&-hourLine {
		block-size: $calendar-hour-height-minimum;

		// we need to check support of lvh, added also max support in case
		@supports (
			block-size: (
				max(1rem, 100lvh),
			)
		) {
			block-size: $calendar-hour-height;
		}

		&::after {
			inline-size: 100%;
			margin-block-start: -1px;
			border-block-end: $calendar-grid-border;
		}
	}

	&-gridcell {
		margin-inline-end: $calendar-gridcell-margin;

		&::after {
			border-inline-start: $calendar-grid-border;
			block-size: 100%;
			margin-inline-start: -1px;
		}
	}

	&-nowHourLine {
		z-index: 8; // Need to be higher than .calendar-eventcell.isSelected's z-index
		inset-inline: 0 (-$calendar-gridcell-margin);
		border-block-end: 2px solid var(--primary);
		box-shadow: var(--shadow-norm);
		pointer-events: none;

		&::before {
			content: '';
			position: absolute;
			inset-block-start: rem(-3);
			inset-inline-start: rem(-4);
			inline-size: rem(7);
			block-size: rem(7);
			border-radius: 50%;
			background-color: var(--primary);
		}
	}

	&-timeBlock {
		block-size: $calendar-hour-height-minimum;

		@supports (
			block-size: (
				max(1rem, 100lvh),
			)
		) {
			block-size: $calendar-hour-height;
		}
	}

	&-timeText {
		inset-block-start: rem(-10);
		font-size: rem(10);
	}
}

.calendar-time-grid {
	@include media('print') {
		flex-basis: auto;
	}
}
