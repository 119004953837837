@import '~@proton/styles/scss/lib';

.calendar-aside {
	inline-size: $calendar-aside-width;
	font-size: rem(10);
}

.calendar-primary-timezone-cell,
.calendar-secondary-timezone-cell {
	border-inline: 1px solid var(--border-norm);
	background-color: var(--background-weak);

	&--header {
		border-block-start: 1px solid var(--border-norm);
		border-inline-end: 1px solid var(--border-norm);
		border-start-end-radius: var(--border-radius-md);
		border-start-start-radius: var(--border-radius-md);
	}
}

.calendar-primary-timezone-cell {
	border-color: transparent;
	background-color: transparent;

	&--header {
		border-color: transparent;
	}
}
